import { useCallback } from "react"
import { SocketEventStatus, SocketEventTypes } from "./types"

import { useSocketEvent } from "./useSocketEvent"
import { message } from "antd"

const SocketMessageEventListener: React.FC = () => {
  const [messageInstance, contextHolder] = message.useMessage()

  const handleMessageDisplay = useCallback(
    ({ socketEventStatus, message }: Record<string, string>) => {
      if (!messageInstance) {
        return
      }

      if (socketEventStatus === SocketEventStatus.CloseMessage) {
        messageInstance.destroy()
        return
      }

      if (!message) {
        return
      }

      if (socketEventStatus === SocketEventStatus.Pending) {
        messageInstance.destroy()
        messageInstance.info({ content: message, duration: 0 })
      } else if (socketEventStatus === SocketEventStatus.Success) {
        messageInstance.destroy()
        messageInstance.success({ content: message })
      } else if (socketEventStatus === SocketEventStatus.Error) {
        messageInstance.destroy()
        messageInstance.error({ content: message })
      }
    },
    [messageInstance]
  )

  useSocketEvent(SocketEventTypes.Message, handleMessageDisplay)

  return contextHolder
}

export default SocketMessageEventListener
