// node env = development|production|test
const NODE_ENV = process.env.NODE_ENV || "development"
export const __DEV__ = NODE_ENV === "development"
export const __TEST__ = NODE_ENV === "test"

// app env = development|staging|production
export const APP_ENV = process.env.NEXT_PUBLIC_ENVIRONMENT || "development"
export const IS_DEV = APP_ENV === "development"
export const IS_STAGING = APP_ENV === "staging"
export const IS_PROD = APP_ENV === "production"

//
// DO NOT ADD HERE ANY OTHER ENV VARIABLES
//
