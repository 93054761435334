import { configureStore } from "@reduxjs/toolkit"
import appointmentsReducer from "app/appointments/redux/slice"
import { appointmentsApi } from "app/appointments/redux/apis"

const store = configureStore({
  reducer: {
    appointments: appointmentsReducer,
    [appointmentsApi.reducerPath]: appointmentsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(appointmentsApi.middleware),
})

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>

export default store
