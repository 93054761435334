import * as Sentry from "@sentry/nextjs"

export function logError(error: Error, additionalMessage?: string, verbose = true) {
  if (verbose) {
    if (additionalMessage) {
      console.error(additionalMessage, error)
    } else {
      console.error(error)
    }
  }

  return Sentry.captureException(error, {
    extra: {
      message: additionalMessage ?? error?.message,
    },
  })
}
