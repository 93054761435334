import { createSlice } from "@reduxjs/toolkit"
import { WritableDraft } from "immer/dist/internal"
import { AppointmentExtended } from "../types"
import dayjs from "dayjs"
import { appointmentsApi } from "./apis"
import {
  AppointmentsState,
  RemoveAppointmentActionType,
  SetCalendarMonthAndYearActionType,
} from "./types"
import omit from "lodash/omit"
import orderBy from "lodash/orderBy"

const initialState: AppointmentsState = {
  byId: {},
  allIds: [],
  numberOfAppointmentsToday: null,
  calendarMonth: new Date().getMonth(),
  calendarYear: new Date().getFullYear(),
  loading: null,
  error: null,
  ownCalendarConnected: null,
}

const calculateAppointmentsToday = (
  appointments: AppointmentExtended[] | WritableDraft<AppointmentExtended>[]
) => appointments.filter((appointment) => dayjs(appointment.start).isSame(dayjs(), "day")).length

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    setCalendarMonthAndYear: (state, action: SetCalendarMonthAndYearActionType) => {
      const date = action.payload

      state.calendarMonth = date.getMonth()
      state.calendarYear = date.getFullYear()
    },
    removeAppointment: (state, action: RemoveAppointmentActionType) => {
      const appointmentId = action.payload

      state.byId = omit(state.byId, appointmentId)
      state.allIds = state.allIds.filter((id) => id !== appointmentId)
      state.numberOfAppointmentsToday = calculateAppointmentsToday(Object.values(state.byId))
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      appointmentsApi.endpoints.getAppointments.matchFulfilled,
      (state, action) => {
        action.payload?.forEach((appointment) => {
          state.byId[appointment.id] = appointment
        })

        const orderedArray = orderBy(Object.values(state.byId), ["start"], ["asc"])
        state.allIds = orderedArray.map(({ id }) => id)

        state.numberOfAppointmentsToday = calculateAppointmentsToday(Object.values(state.byId))
      }
    ),
      builder.addMatcher(
        appointmentsApi.endpoints.checkCalendarConnection.matchFulfilled,
        (state, action) => {
          state.ownCalendarConnected = action.payload
        }
      )
  },
})

export const { setCalendarMonthAndYear, removeAppointment } = appointmentsSlice.actions

export default appointmentsSlice.reducer
