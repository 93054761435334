import socketIOClient, { Socket } from "socket.io-client"
import { useMemo, createContext, useContext, useState, useEffect } from "react"
import { invoke } from "@blitzjs/rpc"
import registerSocketId from "app/auth/mutations/register-socketId"
import { logError } from "integrations/sentry"

export interface SocketContextValue {
  socket: Socket | null
}

export const SocketContext = createContext<SocketContextValue>({
  socket: null,
})

function useSocketContextProvider() {
  const [socket, setSocket] = useState<Socket | null>(null)

  useEffect(() => {
    const socket = socketIOClient(process.env.NEXT_PUBLIC_BASE_URL!, {
      transports: ["websocket"], // remove this once sticky sessions are enabled in AWS
    })

    socket.on("connect", async () => {
      try {
        await invoke(registerSocketId, { socketId: socket.id })
      } catch (error) {
        logError(error, "Failed to register socket in session")
      }

      setSocket(socket)
    })
  }, [])

  const contextValue = useMemo((): SocketContextValue => {
    return {
      socket,
    }
  }, [socket])

  return contextValue
}

export function useSocketContext() {
  const context = useContext(SocketContext)
  return context
}

type Props = React.PropsWithChildren<{}>

export const SocketContextProvider: React.FC<Props> = ({ children }) => {
  const contextValue = useSocketContextProvider()

  return <SocketContext.Provider value={contextValue}>{children}</SocketContext.Provider>
}
