import { z } from "zod"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { AppointmentExtended } from "../types"
import { HttpMethods } from "@Types/common"
import { createApiRequestBody } from "@Utils/client-api-utils"
import {
  CreateAppointmentSchema,
  GetAppointmentsSchema,
  RemoveAppointmentSchema,
  UpdateAppointmentSchema,
  UpdateAppointmentTouchpointIdSchema,
} from "../validations"
import { removeAppointment } from "./slice"

export const appointmentsApi = createApi({
  reducerPath: "appointmentsApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/appointments/" }),
  tagTypes: ["appointments"],
  endpoints: (builder) => ({
    getAppointments: builder.query<AppointmentExtended[], z.infer<typeof GetAppointmentsSchema>>({
      query: ({ start, end }) => `get-appointments?start=${start}&end=${end}`,
      providesTags: ["appointments"],
    }),
    removeAppointment: builder.mutation<void, z.infer<typeof RemoveAppointmentSchema>>({
      query: ({ appointmentId }) =>
        createApiRequestBody(
          `remove-appointment?appointmentId=${appointmentId}`,
          HttpMethods.DELETE
        ),
      async onQueryStarted({ appointmentId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(removeAppointment(appointmentId))
        } catch (err) {}
      },
      invalidatesTags: ["appointments"],
    }),
    updateAppointment: builder.mutation<
      AppointmentExtended[],
      z.infer<typeof UpdateAppointmentSchema>
    >({
      query: (appointmentData) =>
        createApiRequestBody(`update-appointment`, HttpMethods.POST, appointmentData),
      invalidatesTags: ["appointments"],
    }),
    updateAppointmentTouchpointId: builder.mutation<
      AppointmentExtended[],
      z.infer<typeof UpdateAppointmentTouchpointIdSchema>
    >({
      query: (appointmentAndTouchpointId) =>
        createApiRequestBody(
          `update-appointment-touchpoint-id`,
          HttpMethods.POST,
          appointmentAndTouchpointId
        ),
      invalidatesTags: ["appointments"],
    }),
    createAppointment: builder.mutation<
      AppointmentExtended[],
      z.infer<typeof CreateAppointmentSchema>
    >({
      query: (appointmentData) =>
        createApiRequestBody(`create-appointment`, HttpMethods.POST, appointmentData),
      invalidatesTags: ["appointments"],
    }),
    checkCalendarConnection: builder.query<boolean, void>({
      query: () => `check-calendar-connection`,
    }),
    connectWebhookAndSync: builder.mutation<void, void>({
      query: () => `connect-webhook-and-sync`,
      invalidatesTags: ["appointments"],
    }),
  }),
})

export const {
  useGetAppointmentsQuery,
  useRemoveAppointmentMutation,
  useUpdateAppointmentMutation,
  useUpdateAppointmentTouchpointIdMutation,
  useCreateAppointmentMutation,
  useCheckCalendarConnectionQuery,
  useConnectWebhookAndSyncMutation,
} = appointmentsApi
