export enum SocketEventTypes {
  SignatureAdded = "signatureAdded",
  Message = "message",
  RefetchCustomer = "refetchCustomer",
  RefetchOpportunities = "refetchOpportunities",
  ReloadRapportNotes = "reloadRapportNotes",
  TouchpointSent = "touchpointSent",
  NbaDismissed = "nbaDismissed",
  IntakeFlowPledgeSent = "intakeFlowPledgeSent",
  TouchpointReactionNotification = "touchpointReactionNotification",
}

export enum SocketEventStatus {
  Pending = "pending",
  Success = "success",
  Error = "error",
  CloseMessage = "closeMessage",
}
