import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect"
import useMediaQuery from "./useMediaQuery"
import {
  ICON_DARK,
  ICON_LIGHT,
  APPLE_TOUCH_ICON_DARK,
  APPLE_TOUCH_ICON_LIGHT,
} from "../layouts/constants"

function changeIcon(isDarkMode: boolean | null) {
  // Icon
  const iconEl = document.querySelector("link[rel='icon']") as HTMLLinkElement
  const newIcon = isDarkMode ? ICON_DARK : ICON_LIGHT
  if (newIcon !== iconEl.href) {
    iconEl.setAttribute("href", newIcon)
  }

  // Apple touch icon
  const appleTouchIconEl = document.querySelector("link[rel='apple-touch-icon']") as HTMLLinkElement
  const newAppleTouchIcon = isDarkMode ? APPLE_TOUCH_ICON_DARK : APPLE_TOUCH_ICON_LIGHT
  if (newAppleTouchIcon !== appleTouchIconEl.href) {
    appleTouchIconEl.setAttribute("href", newAppleTouchIcon)
  }
}

/*
Normally, the HTML part should be enought:
`<link rel="icon" src="..." media="(prefers-color-scheme: light)" />`
`<link rel="icon" src="..." media="(prefers-color-scheme: dark)" />`

But it doesn't work in all browsers, so we need to use JS to change the favicon.
*/
export default function useThemeFavicon() {
  const isDarkMode = useMediaQuery("(prefers-color-scheme: dark)", false)

  useIsomorphicLayoutEffect(() => {
    changeIcon(isDarkMode)
  }, [isDarkMode])
}
