import { useEffect, useState } from "react"

export default function useMediaQuery(query: string, supressInitial = true) {
  // WARNING: it's recommended to set supressInitial to true to avoid SSR issues (rehydration)
  const [matches, setMatches] = useState<boolean | null>(
    !supressInitial && typeof window !== "undefined" ? window.matchMedia(query).matches : null
  )

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query)

    const handleMatchChange = (e: MediaQueryListEvent) => {
      setMatches(e.matches)
    }

    mediaQueryList.addEventListener("change", handleMatchChange)
    setMatches(mediaQueryList.matches)

    return () => {
      mediaQueryList.removeEventListener("change", handleMatchChange)
    }
  }, [query])

  return matches
}
